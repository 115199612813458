.filter-type-dropdown {
  .dropdown-item {
    &.disabled {
      .dropdown-item-text {
      }
    }
  }
}

.notebook-filters {
  .react-datetimerange-picker {
    .react-datetimerange-picker__wrapper {
      padding-left: 0.5em !important;
      border: 1px solid #ced4da;
      border-right: 1px solid #fff;
    }
  }
  .filter-range-input {
    max-width: 90px;
  }
  .rbt {
    .form-control {
      border-right: 0;
    }
  }
}

.notebook-title {
  font-size: 1.75rem !important;
  font-weight: 500 !important;
  &:focus {
    box-shadow: none !important;
  }
}

.panel-description {
  &:focus {
    box-shadow: none !important;
  }
}
