@import "./variables.scss";

// Globals
body {
  background: $gray-200;
  height: 100vh;
}

.app,
.root {
  height: 100vh;
}

.cursor-pointer {
  cursor: pointer;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  font-size: 1em;
}
.MuiDataGrid-footerContainer {
  border-top: 1px solid rgba(224, 224, 224, 1);
  background: #f9f9f9;
}
.MuiDataGrid-root .MuiDataGrid-window {
  overflow-x: hidden !important;
}

.Select-input > input {
  -moz-transition: none;
  -ms-transition: none;
  -webkit-transition: none;
  transition: none;
}
