.MuiDataGrid-columnsContainer {
  background-color: #0000000a !important;
  font-style: italic !important;
}

.MuiDataGrid-cell {
  font-size: 1rem;
}
.MuiDataGrid-columnsContainer,
.MuiDataGrid-viewport {
  overflow: visible !important;
}
.MuiDataGrid-root .MuiDataGrid-window {
  overflow-y: visible !important;
}

.user-initials-pill {
  border-radius: 50%;
  background: #f0f0f0;
  border: 1px solid #ddd;
  height: 40px;
  width: 40px;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
}

.notebooks-table {
  .MuiDataGrid-cell--withRenderer {
    overflow: visible !important;
    outline: none !important;
  }
}

.owned-by {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .sb-avatar {
    display: flex !important;
    margin-right: 0.3rem;
  }
}

.collaborator-option {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  padding: 0.1rem 1rem;
  .sb-avatar {
    display: flex !important;
    margin-right: 0.3rem;
  }
}

.shared-with {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: .2rem;
  .sb-avatar {
    display: flex !important;
    margin-right: 0.2rem;
  }
}