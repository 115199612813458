.recharts-responsive-container {
  min-height: 400px;
  margin-top: 2em;
}

.analysis {
  > .analysis-item {
    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
}

.analysis-item {
  padding: .5rem .5rem 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #f1f1f1;
}

.answers {}